<script setup lang="ts">
    import {
        type AendereBemerkungAnDieLandesstelleRequest,
        type AendereErgebnisDerBetriebspruefungRequest,
        type AendereInterneBemerkungRequest,
        type Betriebspruefung,
        type MangelDerPruefung,
        type Zertifizierung
    } from '@/js/model'
    import { useUserService } from '@/services'
    import {
        useAlertStore,
        useAuthStore,
        useBetriebspruefungStore,
        useZertifizierungStore
    } from '@/stores'
    import { computed, onMounted, ref, watch } from 'vue'
    import { DokumentPreviewCell, UploadErgebnisDerPruefungDokumentDialog } from '@/components'

    const authStore = useAuthStore()
    const betriebspruefungStore = useBetriebspruefungStore()
    const userService = useUserService()
    const zertifizierungStore = useZertifizierungStore()

    const props = defineProps<{
        betriebspruefung: Betriebspruefung
    }>()

    const emit = defineEmits<{
        itemChanged: [event: Betriebspruefung]
    }>()

    const alertStore = useAlertStore()

    const showDialog = ref<boolean>(false)
    const isLoading = ref<boolean>(false)
    const isGeaendertVonNichtFachpruefer = ref<false | string>(false)
    const bemerkungAnDieLandesstelle = ref<string>('')
    const interneBemerkung = ref<string>('')
    const selectedMangel = ref<MangelDerPruefung | undefined>(undefined)
    const mangelBeschreibungSelect = ref<HTMLInputElement | null>(null)
    const mangelBeschreibung = ref<string>('')
    const mangelBeschreibungItem = ref<string[]>([])
    const mangelBeschreibungItems = ref<{ title: string; value: string }[]>([
        {
            title: '"Nur" Anmeldung WS Betriebsbeauftragter',
            value: '- "Nur" Anmeldung WS Betriebsbeauftragter\n'
        },
        {
            title: 'fehlt Anmeldung Wiederholungsschulung Betriebsbeauftragter',
            value: '- fehlt Anmeldung Wiederholungsschulung Betriebsbeauftragter\n'
        },
        {
            title: 'fehlt WS Betriebsbeauftragter',
            value: '- fehlt WS Betriebsbeauftragter\n'
        },
        {
            title: 'fehlt Schulung MA',
            value: '- fehlt Schulung MA\n'
        },
        {
            title: 'SV Prüfbericht fehlt / Nachweis fachliche Leistung',
            value: '- SV Prüfbericht fehlt / Nachweis fachliche Leistung\n'
        },
        {
            title: 'Werkzeug: Nassprüfgerät fehlt',
            value: '- Werkzeug: Nassprüfgerät fehlt\n'
        },
        {
            title: 'Nachweis Qualifikation Meister',
            value: '- Nachweis Qualifikation Meister\n'
        },
        {
            title: 'Nachweis Qualifikation FBS',
            value: '- Nachweis Qualifikation FBS\n'
        },
        {
            title: 'technisches Regelwerk fehlt',
            value: '- technisches Regelwerk fehlt\n'
        }
    ])

    const mangelItems = ref([
        {
            mangel: '<nicht erfasst>',
            mangelKey: 0
        },
        {
            mangel: 'kein – Betriebsprüfung bestanden',
            mangelKey: 1
        },
        {
            mangel: 'geringfügig – Betriebsprüfung bestanden',
            mangelKey: 2
        },
        {
            mangel: 'mittel – Betriebsprüfung durchgefallen',
            mangelKey: 3
        },
        {
            mangel: 'erheblich – Betriebsprüfung durchgefallen',
            mangelKey: 4
        }
    ])

    const mangelLabel = computed(() => {
        switch (props.betriebspruefung.ergebnisDerBetriebspruefung.mangel?.mangelKey) {
            case 0:
                return '<nicht erfasst>'
            case 1:
                return 'kein Mangel'
            case 2:
                return 'geringfügiger Mangel'
            case 3:
                return 'mittlerer Mangel'
            case 4:
                return 'erheblicher Mangel'
            default:
                return ''
        }
    })

    const zertifizierung = computed<Zertifizierung | null>(() => {
        return zertifizierungStore.getZertifizierungById(props.betriebspruefung.zertifizierungId)
    })

    const bgClass = computed(() => {
        if (props.betriebspruefung.isAbgeschlossen) {
            return 'white'
        }

        switch (props.betriebspruefung.ergebnisDerBetriebspruefung.mangel?.mangelKey) {
            case 0:
                return 'grey'
            case 1:
                return 'green'
            case 2:
            case 3:
                return 'yellow'
            case 4:
                return 'red'
            default:
                return ''
        }
    })

    const isLandesstelle = computed(() => {
        return authStore.hasRole('Landesstelle')
    })

    const isUewg = computed(() => {
        return authStore.hasRole('Uewg')
    })

    const isAllowedToChangeAfterBetriebspruefungAbgeschlossen = computed(() => {
        return (
            (isUewg.value || isLandesstelle.value) &&
            zertifizierung.value &&
            props.betriebspruefung.isAbgeschlossen
        )
    })

    const isDokumentPreviewWithDelete = computed(() => {
        if (isLoading.value) {
            return false
        }

        return (
            isUewg.value ||
            isLandesstelle.value ||
            (authStore.hasRole('Fachpruefer') && !props.betriebspruefung.isAbgeschlossen)
        )
    })

    async function updateGeaendertVonNonFachpruefer() {
        const geaendertVon = props.betriebspruefung.ergebnisDerBetriebspruefungGeaendertVon

        if (!geaendertVon || !geaendertVon.userId || geaendertVon.type !== 'user') {
            isGeaendertVonNichtFachpruefer.value = false
            return
        }

        const user = await userService.getUserById(geaendertVon.userId)
        if (!user) {
            isGeaendertVonNichtFachpruefer.value = false
            return
        }

        const userRollen = user.rollen.rollen
        let userType = 'unbekannte Organisation'
        const isFachpruefer = userRollen.some((rolle) => rolle.isForFachpruefer)
        if (isFachpruefer) {
            // Do not show info if the result was changed by a Fachprüfer
            isGeaendertVonNichtFachpruefer.value = false
        }
        if (userRollen.some((rolle) => rolle.isUewg)) {
            userType = 'ÜWG'
        } else if (userRollen.some((rolle) => rolle.isForLandesstelle)) {
            userType = 'Landesstelle'
        }

        isGeaendertVonNichtFachpruefer.value = `geändert durch ${user.vorname} ${user.nachname} (${userType})`
    }

    const isErgebnisDerBetriebspruefungSubmitDisabled = computed(() => {
        const ergebnisDerBetriebspruefung = props.betriebspruefung.ergebnisDerBetriebspruefung
        return (
            isLoading.value ||
            (selectedMangel.value?.mangelKey === ergebnisDerBetriebspruefung.mangel.mangelKey &&
                mangelBeschreibung.value === ergebnisDerBetriebspruefung.bemerkung)
        )
    })

    const isBemerkungAnDieLandesstelleSubmitDisabled = computed(() => {
        return (
            isLoading.value ||
            bemerkungAnDieLandesstelle.value === zertifizierung.value?.bemerkungAnDieLandesstelle
        )
    })

    const isInterneBemerkungSubmitDisabled = computed(() => {
        return isLoading.value || interneBemerkung.value === zertifizierung.value?.interneBemerkung
    })

    function setInitialValues() {
        const ergebnisDerBetriebspruefung = props.betriebspruefung.ergebnisDerBetriebspruefung
        selectedMangel.value = JSON.parse(JSON.stringify(ergebnisDerBetriebspruefung.mangel))
        mangelBeschreibung.value = ergebnisDerBetriebspruefung.bemerkung
        setInitialValuesForZertifizierung()

        mangelBeschreibungItem.value = []
        mangelBeschreibungItems.value.forEach((item) => {
            if (!mangelBeschreibung.value.includes(item.value)) {
                return
            }
            if (mangelBeschreibungItem.value.includes(item.value)) {
                return
            }
            mangelBeschreibungItem.value.push(item.value)
        })
        updateGeaendertVonNonFachpruefer()
    }

    function setInitialValuesForZertifizierung() {
        if (zertifizierung.value) {
            bemerkungAnDieLandesstelle.value = zertifizierung.value?.bemerkungAnDieLandesstelle
            interneBemerkung.value = zertifizierung.value?.interneBemerkung
        }
    }

    onMounted(async () => {
        if (!zertifizierung.value) {
            await zertifizierungStore.fetchZertifizierungById(
                props.betriebspruefung.zertifizierungId
            )
        }
        setInitialValues()
    })

    const onItemChanged = (result: Betriebspruefung) => {
        emit('itemChanged', result)
    }

    const onMangelBeschreibungItemSelect = () => {
        mangelBeschreibungItems.value.forEach((item) => {
            let isFound = false
            mangelBeschreibungItem.value.forEach((newItemValue) => {
                if (newItemValue === item.value) {
                    isFound = true
                }
            })
            if (!isFound) {
                if (mangelBeschreibung.value.includes(item.value)) {
                    mangelBeschreibung.value = mangelBeschreibung.value.replace(item.value, '')
                }
            } else {
                if (!mangelBeschreibung.value.includes(item.value)) {
                    mangelBeschreibung.value += item.value
                }
            }
        })
    }

    const handleErgebnisDerBetriebspruefungChange = async () => {
        const request: AendereErgebnisDerBetriebspruefungRequest = {
            betriebspruefung: props.betriebspruefung['@id'],
            mangelDerPruefung: selectedMangel.value,
            bemerkung: mangelBeschreibung.value
        }
        isLoading.value = true
        try {
            const result = await betriebspruefungStore.aendereErgebnisDerBetriebspruefung(request)
            alertStore.success('Ergebnis der Prüfung erfolgreich geändert.')
            emit('itemChanged', result)
            updateGeaendertVonNonFachpruefer()
        } catch (error) {
            const message = 'Beim Speichern des Prüfungsergebnisses ist etwas schiefgelaufen.'
            alertStore.error(message)
            throw new Error(message)
        } finally {
            isLoading.value = false
        }
    }

    const handleBemerkungAnDieLandesstelleChange = async () => {
        if (zertifizierung.value) {
            const request: AendereBemerkungAnDieLandesstelleRequest = {
                zertifizierung: zertifizierung.value['@id'],
                bemerkungAnDieLandesstelle: bemerkungAnDieLandesstelle.value ?? ''
            }
            isLoading.value = true
            try {
                const result = await zertifizierungStore.aendereBemerkungAnDieLandesstelle(request)

                zertifizierungStore.addOrReplaceZertifizierung(result)
                alertStore.success(
                    'Bemerkungen des Fachprüfers für die Landesstelle erfolgreich geändert.'
                )
                return
            } catch (error) {
                const message =
                    'Beim Speichern der Bemerkungen des Fachprüfers für die Landesstelle ist etwas schiefgelaufen.'
                alertStore.error(message)
                throw new Error(message)
            } finally {
                isLoading.value = false
            }
        }
    }

    const handleInterneBemerkungChange = async () => {
        if (zertifizierung.value) {
            const request: AendereInterneBemerkungRequest = {
                zertifizierung: zertifizierung.value['@id'],
                interneBemerkung: interneBemerkung.value ?? ''
            }
            isLoading.value = true
            try {
                const result = await zertifizierungStore.aendereInterneBemerkung(request)

                zertifizierungStore.addOrReplaceZertifizierung(result)
                alertStore.success('Interne Bemerkungen zur Betriebsprüfung erfolgreich geändert.')
                return
            } catch (error) {
                const message =
                    'Beim Speichern der internen Bemerkungen zur Betriebsprüfung ist etwas schiefgelaufen.'
                alertStore.error(message)
                throw new Error(message)
            } finally {
                isLoading.value = false
            }
        }
    }

    watch([zertifizierung], async () => {
        setInitialValuesForZertifizierung()
    })

    const handleDismiss = async () => {
        showDialog.value = false
        setInitialValues()
    }
</script>

<template>
    <v-chip v-if="betriebspruefung.isAbgeschlossen && selectedMangel" color="primary" label>
        {{ mangelLabel }}
    </v-chip>
    <v-btn
        v-else
        append-icon="mdi-pencil"
        variant="elevated"
        :color="bgClass"
        size="small"
        class="my-1"
        @click="showDialog = true"
    >
        {{ mangelLabel }}
    </v-btn>
    <v-tooltip
        v-if="isGeaendertVonNichtFachpruefer"
        :text="isGeaendertVonNichtFachpruefer"
        open-on-click
    >
        <template v-slot:activator="{ props }">
            <v-icon
                v-bind="props"
                class="ml-1 d-inline-block"
                color="info"
                data-test="ergebnisDerBetriebspruefung-info"
            >
                mdi-information-variant-circle
            </v-icon>
        </template>
    </v-tooltip>
    <v-alert v-if="!betriebspruefung.isAbgeschlossen" density="compact" variant="plain">
        {{ betriebspruefung.dokumentIds.length }} Dokument{{
            1 === betriebspruefung.dokumentIds.length ? '' : 'e'
        }}
        <div class="text-no-wrap text-truncate">
            {{ betriebspruefung.ergebnisDerBetriebspruefung.bemerkung }}
        </div>
    </v-alert>
    <v-btn
        v-if="selectedMangel && isAllowedToChangeAfterBetriebspruefungAbgeschlossen"
        variant="text"
        size="small"
        class="my-1 mx-1"
        title="Ergebnis der Betriebsprüfung nachträglich ändern"
        @click="showDialog = true"
    >
        <v-icon>mdi-pencil</v-icon>
    </v-btn>

    <v-dialog v-model="showDialog" max-width="1800" @after-leave="handleDismiss">
        <v-card
            density="compact"
            prepend-icon="mdi-file-outline"
            title="Ergebnis der Betriebsprüfung ändern"
        >
            <v-card-text>
                <v-card density="compact">
                    <v-card-text>
                        <v-select
                            label="Grad des Mangels"
                            v-model="selectedMangel"
                            :disabled="isLoading"
                            :items="mangelItems"
                            item-title="mangel"
                            item-value="mangelKey"
                            return-object
                            variant="solo-filled"
                        />

                        <v-divider class="mb-5"></v-divider>

                        <v-select
                            ref="mangelBeschreibungSelect"
                            v-model="mangelBeschreibungItem"
                            :disabled="isLoading"
                            :items="mangelBeschreibungItems"
                            density="compact"
                            multiple
                            variant="outlined"
                            hide-details
                            clearable
                            max-width="600px"
                            class="ml-auto mb-2"
                            label="Schnellauswahl für Beschreibung des Mangels"
                            data-test="mangel-beschreibung-select"
                            placeholder="Der gewählte Text wird zur Beschreibung des Mangels hinzugefügt."
                            @update:modelValue="onMangelBeschreibungItemSelect"
                            single-line
                        >
                            <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index < 1">
                                    <span>{{ item.title }}</span>
                                </v-chip>
                                <span
                                    v-if="index === 1"
                                    class="text-grey text-caption align-self-center"
                                >
                                    (+{{ mangelBeschreibungItem.length - 1 }} weitere)
                                </span>
                            </template>
                        </v-select>
                        <v-textarea
                            v-model="mangelBeschreibung"
                            :disabled="isLoading"
                            label="Beschreibung des Mangels"
                            hint="Welche Mängel sollte der Fachbetrieb beheben."
                            rows="8"
                            no-resize
                            :counter="2000"
                            clearable
                            variant="solo-filled"
                        />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            text="Speichern"
                            :disabled="isErgebnisDerBetriebspruefungSubmitDisabled"
                            variant="tonal"
                            @click="handleErgebnisDerBetriebspruefungChange"
                            data-test="action-pruefungsergebis-save"
                        ></v-btn>
                    </v-card-actions>
                </v-card>
            </v-card-text>
            <v-card-text>
                <v-card density="compact">
                    <v-card-text>
                        <v-textarea
                            v-model="bemerkungAnDieLandesstelle"
                            :disabled="isLoading"
                            :readonly="isUewg"
                            label="Bemerkungen des Fachprüfers für die Landesstelle (optional)"
                            hint=""
                            auto-grow
                            density="compact"
                            :counter="2000"
                            :clearable="!isUewg"
                            variant="solo-filled"
                        />
                        <v-alert
                            v-if="isUewg"
                            type="info"
                            color="grey"
                            variant="outlined"
                            icon="mdi-information-outline"
                        >
                            Sie können als ÜWG-Nutzer keine Bemerkung bearbeiten.
                        </v-alert>
                    </v-card-text>

                    <v-card-actions v-if="!isUewg">
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            text="Speichern"
                            title="Bemerkungen des Fachprüfers für die Landesstelle speichern"
                            :disabled="isBemerkungAnDieLandesstelleSubmitDisabled"
                            variant="tonal"
                            @click="handleBemerkungAnDieLandesstelleChange"
                            data-test="action-bemerkung-save"
                        />
                    </v-card-actions>
                </v-card>
            </v-card-text>
            <v-card-text v-if="isUewg || isLandesstelle">
                <v-card density="compact">
                    <v-card-text>
                        <v-textarea
                            v-model="interneBemerkung"
                            :disabled="isLoading"
                            label="Interne Bemerkungen zur Betriebsprüfung von Landesstelle und ÜWG"
                            hint="Schreiben und Lesen dürfen nur Landesstellen und ÜWG"
                            auto-grow
                            density="compact"
                            :counter="2000"
                            variant="solo-filled"
                            data-test="interne-bemerkung-der-zertifizierung"
                        />
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            text="Speichern"
                            title="interne Bemerkungen zur Betriebsprüfung speichern"
                            :disabled="isInterneBemerkungSubmitDisabled"
                            variant="tonal"
                            @click="handleInterneBemerkungChange"
                            data-test="action-interne-bemerkung-save"
                        />
                    </v-card-actions>
                </v-card>
            </v-card-text>
            <v-card-text>
                <v-card density="compact">
                    <v-card-title>Dokumente zur Betriebsprüfung</v-card-title>
                    <v-card-text class="pa-1">
                        <v-container fluid>
                            <v-row>
                                <v-col
                                    v-for="dokumentId in betriebspruefung.dokumentIds"
                                    :key="dokumentId"
                                >
                                    <DokumentPreviewCell
                                        :dokument-id="dokumentId"
                                        :is-with-delete="isDokumentPreviewWithDelete"
                                        :key="dokumentId"
                                        class="grid"
                                    />
                                </v-col>
                                <v-col v-if="!betriebspruefung.dokumentIds.length">
                                    Noch keine Dokumente hinzugefügt.
                                </v-col>
                            </v-row>
                        </v-container>

                        <UploadErgebnisDerPruefungDokumentDialog
                            :betriebspruefung="betriebspruefung"
                            @item-changed="onItemChanged"
                        />
                    </v-card-text>
                </v-card>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn
                    text="Schließen"
                    block
                    variant="tonal"
                    color="primary"
                    @click="handleDismiss"
                    data-test="action-close"
                ></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped></style>
